@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
  margin: 0;
  font-size: 80px;
}
